import { ArrangeProps, arrange } from './arrange';

/**
 * Component for placing items horizontally
 *
 * Responsibilities:
 *
 * 1. Place items next to each other
 * 2. Spacing between items
 * 3. Alignment of items
 */
export const shelf = (props: Omit<ArrangeProps<'shelf'>, 'layout'>) => {
	return arrange({ ...props, layout: 'shelf' });
};
