import { classes } from '../base/classes.helpers';
import { iconBase, iconSprinkles } from './icon.css';

export type IconProps = Parameters<typeof iconSprinkles>[number] & {
	icon: string;
};

/**
 * Use an SVG icon in your UI. See `generateMaskIcon.tsx` on how to create icons.
 *
 * Use `adjustIcon` to turn and flip icons in their base state.
 * If an icon needs to be adjusted due to state use `rotate` and `flip`.
 * This way the two adjustments can be combined.
 */
export const icon = ({ icon, ...props }: IconProps) => {
	return classes(iconBase, iconSprinkles(props), icon);
};
