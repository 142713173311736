import {
	ColumnsProps,
	GridStackProps,
	ShelfProps,
	arrangeSprinkles,
} from './arrange.css';
import { AlignChildrenProps } from './layout.definitions.css';

type AllProps = Parameters<typeof arrangeSprinkles>[0];
type BaseProps = Omit<
	Parameters<typeof arrangeSprinkles>[0],
	AlignChildrenProps | ShelfProps | ColumnsProps | 'arrange'
>;

type Layouts = AllProps['layout'];

export type ArrangeProps<Layout extends Layouts> = {
	layout: Layout;
} & (Layout extends string
	? BaseProps &
			(Layout extends 'shelf'
				? Pick<AllProps, ShelfProps>
				: Record<never, never>) &
			(Layout extends 'columns'
				? Pick<AllProps, ColumnsProps>
				: Pick<AllProps, AlignChildrenProps>) &
			(Layout extends 'gridStack'
				? Pick<AllProps, GridStackProps>
				: Record<never, never>)
	: Omit<AllProps, 'layout'>);

export const arrange = <Layout extends Layouts>(props: ArrangeProps<Layout>) =>
	arrangeSprinkles(props);
